import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad } from '@angular/router';
import { BaseService } from '../services/core/base.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private user: BaseService, private router: Router) {
    
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.user.isLoggedIn()) {
      if (state.url.includes('signup') || state.url.includes('login')) {
        return true;
      }
      else {
        this.router.navigate(['/login']);
        return false;
      }
    }
    else {
      if (state.url.includes('signup') || state.url.includes('login')) {
        this.router.navigate(['/home']);
        return false;
      }
      else {
        return true;
      }
    }


  }

}
