import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './shared/header/header.component';
import { IonicModule } from '@ionic/angular';


@NgModule({
    imports: [CommonModule, IonicModule],
    declarations: [HeaderComponent],
    exports: [HeaderComponent, CommonModule, FormsModule]
})
export class SharedModule { }