import { Injectable } from '@angular/core';
import { APIService, APIVS, Apimethods } from '../core/api.service';
import { APIResult } from '../core/enums';
import { map, catchError } from 'rxjs/operators';
import { CoreService } from '../core/core.service';
import { HttpErrorHandler } from '../core/HttpErrorHandler.service';
import { BaseService } from '../core/base.service';
import { LoginResponse } from '../models/models';

@Injectable()
export class UserService extends CoreService {


    constructor(private api: APIService, private httpErrorHandler: HttpErrorHandler) {
        super();
        this.handleError = httpErrorHandler.createHandleError('HomeService');

    }


    Login(cuser) {
        return this.api.post<LoginResponse>(APIVS.V1, Apimethods.Login, cuser).pipe(
            map((res: LoginResponse) => {
                if (res.success) {
                    this.completeLoginInformation(res,res.user.shopID);
                }
                return res;

            }),
            catchError(this.handleError('Login', []))
        );
    }

    register(cuser) {
        return this.api.post<LoginResponse>(APIVS.V1, Apimethods.Register, cuser).pipe(
            map((res: LoginResponse) => {
                if (res.success) {
                    this.completeLoginInformation(res,cuser.shopId);
                }
                return res;

            }),
            catchError(this.handleError('Login', []))
        );
    }


    completeLoginInformation(LoginResponse: LoginResponse, shopid: number) {
        this.api.base.setAuthToken(LoginResponse.token);
        this.api.base.setUserInformation(LoginResponse.user);
        this.api.base.setShopId(shopid);
    };
}