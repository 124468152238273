import { Injectable } from '@angular/core';
import { APIService, APIVS, Apimethods } from '../core/api.service';
import { map, catchError } from 'rxjs/operators';
import { CoreService } from '../core/core.service';
import { HttpErrorHandler } from '../core/HttpErrorHandler.service';
import { BaseResponse, MenuResponse, MenuToping } from '../models/models';

@Injectable()
export class MenuService extends CoreService {


    constructor(private api: APIService, private httpErrorHandler: HttpErrorHandler) {
        super();
        this.handleError = httpErrorHandler.createHandleError('MenuService');

    }


    getMenu() {
        return this.api.get<BaseResponse<MenuResponse[]>>(APIVS.V1, Apimethods.GetMenu, {}, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<MenuResponse[]>) => {
                return res;
            }),
            catchError(this.handleError('getMenu', []))
        );
    }

    getMenuTooping(id) {
        return this.api.get<BaseResponse<MenuToping[]>>(APIVS.V1, Apimethods.GetMenuToping, {}, id).pipe(
            map((res: BaseResponse<MenuToping[]>) => {
                return res;
            }),
            catchError(this.handleError('getMenu', []))
        );
    }

    changeItemStatus(Id, instock) {
        return this.api.post<BaseResponse<boolean>>(APIVS.V1, Apimethods.ChangeItemStatus, { Id, instock }, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<boolean>) => {
                return res;
            }),
            catchError(this.handleError('changeItemStatus', []))
        );
    }

    UpdateShiftTiming(Id, none, breakfast, lunch, snack, dinner) {
        return this.api.post<BaseResponse<boolean>>(APIVS.V1, Apimethods.UpdateShiftTiming, { none, breakfast, lunch, snack, dinner }, Id.toString()).pipe(
            map((res: BaseResponse<boolean>) => {
                return res;
            }),
            catchError(this.handleError('UpdateShiftTiming', []))
        );
    }

}