import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from '../../../services/settings/settings.service';
import { Vendor } from '../../../services/models/models';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Input() header: string;

    vendor: Vendor = new Vendor();

    constructor(public set: SettingsService) { }
    ngOnInit(): void {
        this.set.GetVendor().subscribe();
        this.set.shop$.subscribe((data: Vendor) => {
            this.vendor = data;
        });
    }
}
