import { AuthGuard } from './auth.guard';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginPageModule } from './login/login.module';
import { LoginPage } from './login/login.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './shared/header/header.component';
import { SharedModule } from './app.shared.module';
import { APIService } from '../services/core/api.service';
import { CoreService } from '../services/core/core.service';
import { BaseService } from '../services/core/base.service';
import { UserService } from '../services/user/user.service';
import { EncryptHelper } from '../services/core/encrypthelper.service';
import { HttpErrorHandler } from '../services/core/HttpErrorHandler.service';
import { StorageService } from '../services/core/storage.service';
import { HttpClientModule } from '@angular/common/http';
import { MenuService } from '../services/menu/menu.service';
import { OrderService } from '../services/order/order.service';
import { SettingsService } from '../services/settings/settings.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { Media } from '@ionic-native/media/ngx';

const config: SocketIoConfig = { url: 'https://pub.foodiyoo.com', options: { transports: ['polling']} };
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    SocketIoModule.forRoot(config)

  ],
  providers: [
    Media,
    StatusBar,OneSignal,AuthGuard,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    APIService,CoreService,BaseService,UserService,MenuService,OrderService,SettingsService,
    EncryptHelper,HttpErrorHandler,StorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
