import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { storageKeys, User } from './enums';
import { BehaviorSubject } from "rxjs"
import { EncryptHelper } from './encrypthelper.service';
import { LoadingController, ToastController } from '@ionic/angular';
import * as momenttz from 'moment-timezone';

@Injectable()
export class BaseService {

    public uniqID: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public authToken: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public PNToken: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public userInformation: BehaviorSubject<User> = new BehaviorSubject<User>(new User());
    public shopId: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
   public appversion = '1.1.2';



    constructor(private storage: StorageService, public enc: EncryptHelper,
        public loadingController: LoadingController, public toastController: ToastController) {

    }



    async showLoading(message) {
        let loading: HTMLIonLoadingElement;
        loading = await this.loadingController.create({
            message: message
        });
        await loading.present();
        return loading;
    }



    async showToast(message) {
        const toast = await this.toastController.create({
            message: message,
            duration: 2000,
            position: 'top'
        });
        toast.present();
    }



    async  setAllFromStorage() {

        await this.storage.get(storageKeys.authToken).then(data => {
            if (data) {
                let acc = this.enc.decrypt(data);
                this.authToken.next(acc);
            }
        });

        await this.storage.get(storageKeys.userInformation).then(data => {
            if (data)
                this.userInformation.next(JSON.parse(this.enc.decrypt(data)));
        });

        await this.storage.get(storageKeys.shopId).then(data => {
            if (data)
                this.shopId.next(Number(this.enc.decrypt(data)));
        });


        await this.storage.get(storageKeys.PN).then(data => {
            if (data)
                this.PNToken.next(data);
        });


    }


    setUniqID(data) {
        this.uniqID.next(data);
        this.storage.set(storageKeys.UniqueId, data).then();
    }

    setPnToken(data) {
        this.PNToken.next(data);
        this.storage.set(storageKeys.PN, data).then();
    }

    getPnToken() {
        return this.PNToken.getValue();
    }

    getuniqID() {
        return this.uniqID.getValue();
    }



    setAuthToken(data) {
        this.authToken.next(data);
        this.storage.set(storageKeys.authToken, this.enc.encrypt(data)).then();
    }

    setUserInformation(data) {
        this.userInformation.next(data);
        this.storage.set(storageKeys.userInformation, this.enc.encrypt(JSON.stringify(data))).then();
    }

    setShopId(data: number) {
        this.shopId.next(data);
        this.storage.set(storageKeys.shopId, this.enc.encrypt(data.toString())).then();
    }

    getAuthToken() {
        return this.authToken.getValue();
    }

    getShopId() {
        return this.shopId.getValue();
    }

    getuserinfo() {
        return this.userInformation.getValue();
    }

    isLoggedIn(): boolean {
        return this.getAuthToken() != '';
    }


    logoutUser() {
        this.authToken.next('');
        this.userInformation.next(new User());
        this.storage.delete(storageKeys.authToken).then();
        this.storage.delete(storageKeys.userInformation).then();
        this.storage.delete(storageKeys.shopId).then();
    }



    getDateFormat(date: any, adddays: number, format: string) {
        if (!date) {
            date = new Date();
        }
        var dat;
        if (adddays > 0) {
            dat = momenttz.tz(date, "Asia/Kolkata").add(adddays, 'days');
        }
        else {
            dat = momenttz.tz(date, "Asia/Kolkata");
        }

        let now = momenttz.tz(dat, "Asia/Kolkata").format(format);
        return now;
    }

    FormatDate(date: any, format: string) {
        let now = momenttz.tz(date, "Asia/Kolkata").format(format);
        return now;
    }

    getDateFormatFromNow(date: any, adddays: number) {
        var dat;
        if (adddays > 0) {
            dat = momenttz.tz(date, "Asia/Kolkata").add(adddays, 'days');
        }
        else {
            dat = momenttz.tz(date, "Asia/Kolkata");
        }

        let now = momenttz.tz(dat, "Asia/Kolkata");
        return now.fromNow();
    }




}