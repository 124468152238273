import { User, APIResult } from '../core/enums';

export class LoginResponse extends APIResult {
    user: User;
    token: string;
}

export class BaseResponse<T> {
    data: T;
    success: boolean;
    message: string;
    pageCount: number;
}


export class Item {
    name: string;
    catID: number;
    menuCategory?: any;
    description?: any;
    shortDescription?: any;
    inStock: boolean;
    createdDate: Date;
    status: number;
    isNew: boolean;
    price: number;
    discountPrice: number;
    availableQTY: number;
    img?: any;
    imgFullPath: string;
    url: string;
    orderID: number;
    hasExtraTopping: boolean;
    qty: number;
    id: number;
    menuItemShifts: MenuTimesModule[] = [];

}

export class Vendor {
    name: string = '';
    logo?: any;
    logoFullPath?: any;
    parentID: number;
    status: boolean;
    longitude: string;
    latitude: string;
    expiredDate: Date;
    creadteDate: Date;
    orderID: number;
    rating: number;
    reviews: number;
    phone: string;
    countryID: number;
    acceptanceDuration: number;
    preparationDuration: number;
    openingStatus: number;
    slug?: any;
    menus?: any;
    deliverySlots?: any;
    open: boolean;
    id: number = -1;
    parent: Vendor;
}

export class MenuResponse {
    id: number;
    title: string;
    items: Item[];
}



export class CartAddon {
    cartID: number;
    addonID: number;
    id: number;
}

export class ListOfCart {
    orderID: number;
    itemID: number;
    name: string;
    details: string;
    specialRequest: string;
    price: number;
    qty: number;
    createdDate: Date;
    status: number;
    type: number;
    item: Item;
    cartAddons: CartAddon[];
    id: number;
}

export interface ExtraCharges {
    orderId: number;
    amount: number;
    chargesDate: Date;
    type: number;
    id: number;
}

export interface PaymentDetails {
    amount: number;
    title: string;
    id: number;
}

export class OrderModule {
    extraCharge: number;
    paymentDetails: PaymentDetails[];
    extraCharges: ExtraCharges[];
    servicesCharges?: any;
    userID: number;
    customer?: any;
    userUUID: string;
    paymentMethod: number;
    paymentVendor: number;
    paymentStatus: number;
    channel: number;
    orderDate: Date;
    paymentDate?: any;
    addressID: number;
    deliveryAddress?: any;
    voucherCode: string;
    discountType: number;
    voucherAmount: number;
    vendorID: number;
    vendor?: any;
    vat: number;
    vatAmount: number;
    status: OrderStatus;
    subTotal: number;
    discountAmount: number;
    deliveryCharges: number;
    grandTotal: number;
    paidFromWallet: number;
    type: number;
    resultDate?: any;
    note?: any;
    browser: string;
    isNew: boolean;
    payPalTransID?: any;
    userLocale: number;
    currentStep: number;
    mapAvailable: boolean;
    mapUUID?: any;
    usDoularGrandTotal: number;
    ip: string;
    listOfCart: ListOfCart[];
    generalRequest: string;
    id: number;
    currentStepString: string = '';
}


export class DeliveySlotsModule {
    name: string;
    fromTime: string;
    toTime: string;
    saturday: boolean;
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    status: boolean;
    orderID: number;
    vendorID: number;
    shop?: any;
    id: number = -1;
}



export class MenuTimesModule {
    fromTime: string = '';
    toTime: string = '';
    id: number = -1;
    type: number = 0;
    itemID: number = -1;
}

export enum OrderStatus {
    Not_Confirmed,
    Delivered,
    Confirmed,
    Canceled,
    Under_Delivery,
    Refound
}




export class MenuToping {
    id: number;
    title: string;
    isRequired: boolean;
    maxQty: number;
    minQty: number;
    items: TopingItems[];
}

export class TopingItems {
    title: string;
    catID: number;
    extraToppingCategory?: any;
    price: number;
    status: boolean;
    orderID: number;
    id: number;
}



export class Datum {
    id: string;
    date: string;
    totalOrders: number;
    deliveryCharges: number;
    subTotal: number;
    itemsOriginalPrice: number;
    discountAmount: number;
    gst: number;
    vendorCharges: number;
    packaging: number;
    deliveryVat: number;
    grandTotal: number;
    foodiyooCharges: number;
    vendorShareAmount: number;
    ourShareAmount: number;
    itemDiscount: number;
    netAmount: number;
    voucherVendorShare: number = 0;
    voucherFoodiyooShare: number = 0;
}

export class Total {
    orders: number = 0;
    deliveryCharges: number = 0;
    subTotal: number = 0;
    itemsOriginalPrice: number = 0;
    discountAmount: number = 0;
    gst: number = 0;
    vendorCharges: number = 0;
    packaging: number = 0;
    deliveryVat: number = 0;
    grandTotal: number = 0;
    foodiyooCharges: number = 0;
    itemDiscount: number = 0;
    netAmount: number = 0;
    vendorShareAmount: number = 0;
    ourShareAmount: number = 0;
    voucherVendorShare: number = 0;
    voucherFoodiyooShare: number = 0;
}

export class ReportResponse {
    data: Datum[];
    total: Total = new Total();
}






