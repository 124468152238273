import { Injectable } from '@angular/core';
import { storageKeys } from './enums';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

@Injectable()
export class StorageService {

    constructor() {

    }


    async get(storageKeys: storageKeys) {
        let result = await Storage.get
            ({ key: storageKeys }).then((data) => { return data.value; },
                error => {
                    console.error(error);

                })
        return result;
    }



    set(storageKeys: storageKeys, values: string) {
        return Storage.set({ key: storageKeys, value: values }).then(
            data => { return data; },
            error => console.error(error)
        );

    }



    delete(storageKeys: storageKeys) {
        return Storage.remove({ key: storageKeys }).then(data => { return data; },
            error => console.error(error));
    }


}