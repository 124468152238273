import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CuHttpHeaders, Lang, ServerMode } from './enums';
import { BaseService } from './base.service';



@Injectable()
export class APIService {

    _apiURI: string;
    _serverMode: ServerMode = ServerMode.Local;
    _lang: Lang = Lang.English;
    _headeroptions: HttpHeaders;

    _headeroptionsFiles: HttpHeaders;
    _accessuser = 'Q3jATuSuhK9GnC2y0IGVuY29kZWQgc3RyaW5';
    _accesspassword = '5erAOP54LJIKLQcyXT8C';
    loacl: boolean = true;

    constructor(private http: HttpClient, public base: BaseService) {
        this.setAPiSettings(APIVS.V1);
    }


    setAPiSettings(apivs: APIVS = APIVS.V1) {
        if (this.loacl) {
            this._apiURI = apivs == APIVS.V1 ? APILink.localv1 : APILink.localv1;
            this._serverMode = ServerMode.Local;

        } else {
            this._apiURI = apivs == APIVS.V1 ? APILink.livev1 : APILink.livev2;
            this._serverMode = ServerMode.Live;
        }
        

        this._apiURI = apivs == APIVS.V1 ? APILink.livev1 : APILink.livev2;
        this._serverMode = ServerMode.Live;
    }



    gethttpParms(params: Params): HttpParams {
        let paramsback: HttpParams = new HttpParams();
        if (params) {
            for (let property in params) {
                if (params.hasOwnProperty(property)) {
                    paramsback = paramsback.append(property, params[property]);
                }
            }
        }

        return paramsback;
    }


    buildheaders(apivs: APIVS) {
        var headers: CuHttpHeaders;
        this.setAPiSettings(apivs);
        var _accessToken = this.base.getAuthToken();
        if (_accessToken) {
            headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': ('Basic ' + btoa(this._accessuser + ":" + this._accesspassword)),
                'device': '2',
                'version': this.base.appversion,
                'userToken': _accessToken,
                'uniqID': this.base.getuniqID(),
                'lang': this._lang.toString(),
                countryId: '1',
                pushToken: this.base.getPnToken()

            };
        }
        else {

            headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': ('Basic ' + btoa(this._accessuser + ":" + this._accesspassword)),
                'uniqID': this.base.getuniqID(),
                'device': '2',
                'version': this.base.appversion,
                'lang': this._lang.toString(),
                countryId: '1',
                pushToken: this.base.getPnToken()

            }
        }
        this._headeroptions = new HttpHeaders(headers);
    }


    build(method: Apimethods, ...extrereplace: string[]) {

        let methodstring = method.toString();
        if (extrereplace.length > 0) {
            methodstring = FormatString(methodstring, ...extrereplace);
        }
        return `${this._apiURI}${methodstring}`
    }

    get<T>(apivs: APIVS, method: Apimethods, parms: any, ...extrereplace: string[]): Observable<T> {
        this.buildheaders(apivs);
        const options = { headers: this._headeroptions, params: this.gethttpParms(parms) };
        return this.http.get<T>(this.build(method, ...extrereplace), options).pipe(map((data: T) => { return data }));
    }

    post<T>(apivs: APIVS, method: Apimethods, parms: any, ...extrereplace: string[]): Observable<T> {
        this.buildheaders(apivs);
        const options = { headers: this._headeroptions };
        const body = JSON.stringify(parms);
        return this.http.post<T>(this.build(method, ...extrereplace), body, options).pipe(map((data: T) => { return data }));
    }




}


export enum APILink {
    livev1 = 'https://api.foodiyoo.com/api/',
    betav1 = 'https://sandbox.foodiyoo.com/api/',
    localv1 = 'https://api.dev.foodiyoo.com/api/',

    livev2 = 'https://api.foodiyoo.com/api/',
    betav2 = 'https://sandbox.foodiyoo.com/api/',
    localv2 = 'https://api.dev.foodiyoo.com/api/'
}

export enum APIVS {
    V1 = 1,
    V2 = 2
}

export enum Apimethods {
    Login = 'shop/Login',
    Register = 'auth/register',

    //menu
    GetMenu = 'shop/branches/{0}/menures',
    GetMenuToping = 'shop/branches/{0}/menu/topping',
    ChangeItemStatus = 'shop/branches/{0}/item/status',
    UpdateShiftTiming = 'shop/branches/{0}/item/UpdateShiftTiming',

    // orders
    GetHistoryOrderlist = 'shop/{0}/order/list',
    GetActiveOrderlist = 'shop/{0}/order/active',
    GetActiveOrderAlarm = 'shop/{0}/order/alarm',
    ChangeStatus = 'shop/{0}/order/changeStatus',
    ChangeStep = 'shop/{0}/order/changeStep',

    //orders
    GetOrderreport = 'shop/{0}/order/report',

    //settings
    DeliveySlots = 'shop/{0}/settings/deliveryslots',
    GetMenuTimes = 'shop/{0}/settings/GetMenuTimes',
    CreateMenuTimes = 'shop/{0}/settings/CreateMenuTimes',
    DeliveySlotUpdate = 'shop/{0}/settings/deliveryslot/update',
    DeliveySlotadd = 'shop/{0}/settings/deliveryslot/add',
    UpdateStatus = 'shop/{0}/settings/updatestatus',
    GetVendor = 'shop/{0}/settings',


}

export function FormatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
        str = str.replace(`{${index}}`, val[index]);
    }
    return str;
}