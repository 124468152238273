import { Injectable } from '@angular/core';
import { APIService, APIVS, Apimethods } from '../core/api.service';
import { map, catchError } from 'rxjs/operators';
import { CoreService } from '../core/core.service';
import { HttpErrorHandler } from '../core/HttpErrorHandler.service';
import { BaseResponse, MenuResponse, OrderModule, ReportResponse } from '../models/models';
import { of, Subject, Subscription } from 'rxjs';

@Injectable()
export class OrderService extends CoreService {

    alarmPause = new Subject();

    constructor(private api: APIService, private httpErrorHandler: HttpErrorHandler) {
        super();
        this.handleError = httpErrorHandler.createHandleError('OrderService');

    }


    getHistoryOrderList(page, serachkey, startdate, enddate) {
        return this.api.get<BaseResponse<OrderModule[]>>(APIVS.V1, Apimethods.GetHistoryOrderlist, { page, serachkey, startdate, enddate }, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<OrderModule[]>) => {
                return res;
            }),
            catchError(this.handleError('getHistoryOrderList', []))
        );
    }


    getActiveOrderList(page, serachkey) {
        return this.api.get<BaseResponse<OrderModule[]>>(APIVS.V1, Apimethods.GetActiveOrderlist, { page, serachkey }, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<OrderModule[]>) => {
                return res;
            }),
            catchError(this.handleError('getActiveOrderList', []))
        );
    }

    getOrderAlarm() {
        var shopid = this.api.base.getShopId();
        if (!shopid || shopid == -1)
            return of(null);

        return this.api.get<BaseResponse<number>>(APIVS.V1, Apimethods.GetActiveOrderAlarm, {}, shopid.toString()).pipe(
            map((res: BaseResponse<number>) => {
                return res;
            }),
            catchError(this.handleError('getOrderAlarm', []))
        );
    }

    setPauseAlarm(){
        this.alarmPause.next(true);
    }

    getOrderreport(startdate, enddate) {
        return this.api.get<ReportResponse>(APIVS.V1, Apimethods.GetOrderreport, { startdate, enddate }, this.api.base.getShopId().toString()).pipe(
            map((res: ReportResponse) => {
                return res;
            }),
            catchError(this.handleError('getOrderreport', []))
        );
    }

    changeStatus(orderId, Status) {
        return this.api.post<BaseResponse<boolean>>(APIVS.V1, Apimethods.ChangeStatus, { orderId, Status }, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<boolean>) => {
                return res;
            }),
            catchError(this.handleError('changeStatus', []))
        );
    }
    changeStep(orderId, Step) {
        return this.api.post<BaseResponse<boolean>>(APIVS.V1, Apimethods.ChangeStep, { orderId, Step }, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<boolean>) => {
                return res;
            }),
            catchError(this.handleError('changeStep', []))
        );
    }
}