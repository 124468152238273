import { Component, OnInit } from '@angular/core';

import { Platform, MenuController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BaseService } from '../services/core/base.service';
import { Router } from '@angular/router';
import { SettingsService } from '../services/settings/settings.service';
import { Vendor, BaseResponse } from '../services/models/models';
import { OneSignal, OSDisplayType } from '@ionic-native/onesignal/ngx';
import { Plugins } from '@capacitor/core';
import { interval, Subscription } from 'rxjs';
import { OrderService } from '../services/order/order.service';
import { first } from 'rxjs/operators';
const { App, BackgroundTask } = Plugins;
const worker = new Worker('./workers/alarm.worker', { type: 'module' });
import { Media, MediaObject } from '@ionic-native/media/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  isloggedin: boolean = false;
  audio = new Audio();
  private pauseSubscription: Subscription = new Subscription();

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    public base: BaseService,
    private route: Router,
    private set: SettingsService, public alertController: AlertController,
    private oneSignal: OneSignal,
    private order: OrderService,
    private media: Media
  ) {
    this.initializeApp();
    this.pauseSubscription = this.order.alarmPause.subscribe((value:boolean) => {
      console.log("pause call ...")
      if(this.audio.src){
        this.audio.pause();
      }
    });
  }
  vendor: Vendor = new Vendor();

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Logout?',
      message: 'are you sure you want to logout?',
      buttons: [
        {
          text: 'Yes',
          handler: (blah) => {
            this.logout();
          }
        },
        {
          text: 'No',
          role: 'cancel',
          handler: (blah) => {
          }
        }

      ]
    });

    await alert.present();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.splashScreen.hide();

      this.base.setAllFromStorage().then(() => {
        if (this.base.isLoggedIn()) {
          this.menu.enable(true);
        } else {
          this.menu.enable(false);

        }

        this.statusBar.styleDefault();
        this.isloggedin = this.base.isLoggedIn();
        //  this.backgroundMode.enable();
        if (this.platform.is("ios") || this.platform.is("android")) {
          this.configureNotification();
        }

        if (this.platform.is('android')) {
          this.backgroundTask();
        } else if (this.platform.is('mobileweb') || this.platform.is('desktop') || this.platform.is('pwa')) {
          // this.webTask();
        }

      });

    });
  }



  configureNotification() {


    this.oneSignal.startInit('ec004946-bb60-4dc3-9b70-d6199319500a', '543973505330');
    this.oneSignal.iOSSettings({ kOSSettingsKeyAutoPrompt: true, kOSSettingsKeyInAppLaunchURL: true });
    this.oneSignal.inFocusDisplaying(OSDisplayType.Notification);
    this.oneSignal.enableSound(true);
    this.oneSignal.enableVibrate(true);

    if (this.platform.is('ios')) {
      this.oneSignal.promptForPushNotificationsWithUserResponse().then((acepted) => {
        if (acepted) {
          this.oneSignal.getIds().then((ids) => {
            this.base.setPnToken(ids.userId);
          })
        }
      })
    }


    if (this.platform.is('android')) {
      this.oneSignal.getIds().then((ids) => {
        // console.log("** " + ids.userId)
        this.base.setPnToken(ids.userId);
      })
    }


    this.oneSignal.handleNotificationReceived().subscribe(async (pn) => {
      this.taskRunner()
      await this.base.showToast(pn.payload.body);
    });

    //this.oneSignal.handleNotificationOpened().subscribe((pn) => {
    // do something when a notification is opened

    //});

    this.oneSignal.endInit();

  }

  ngOnInit() {
    this.webWorker();
    this.set.shop$.subscribe((data: Vendor) => {
      this.vendor = data;
    });

  }


  logout() {
    this.base.logoutUser();
    this.route.navigate(['/login']);
  }

  closeMenu() {
    this.menu.close();
  }


  backgroundTask() {
    App.addListener('appStateChange', (state) => {
      if (!state.isActive) {
        let taskId = BackgroundTask.beforeExit(async () => {
          this.taskRunner()
          BackgroundTask.finish({
            taskId
          });
        });
      }
    })
  }


  taskRunner() {
    setInterval(() => {
      this.ApiRun();
    }, 60000);
  }


  ApiRun() {
    console.log("api run ...")
    if (!this.base.isLoggedIn())
      return;
    this.order.getOrderAlarm().pipe(first()).subscribe((da: BaseResponse<number>) => {
      if (da.data > 0) {
        this.audio.muted = false;
        this.audio.src = "../assets/ring.mp3";
        this.audio.load();
      
        this.audio.play();
      }
    });
  }



  webWorker() {
    if (typeof Worker !== 'undefined') {
      // Create a new
      worker.onmessage = ({ data }) => {
        // console.log(`page got message: ${data}`);
        this.ApiRun();
      };

      // worker.postMessage('hello');

    } else {
      // Web workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
      this.taskRunner();
    }
  }

  ionViewWillLeave(){
    this.pauseSubscription.unsubscribe();
  }

}
