import { Injectable } from '@angular/core';
import { APIService, APIVS, Apimethods } from '../core/api.service';
import { map, catchError } from 'rxjs/operators';
import { CoreService } from '../core/core.service';
import { HttpErrorHandler } from '../core/HttpErrorHandler.service';
import { BaseResponse, DeliveySlotsModule, Vendor, MenuTimesModule } from '../models/models';
import { ActionSheetController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SettingsService extends CoreService {


    shop: BehaviorSubject<Vendor> = new BehaviorSubject<Vendor>(new Vendor());
    shop$ = this.shop.asObservable();

    constructor(private api: APIService, private httpErrorHandler: HttpErrorHandler, public actionSheetController: ActionSheetController) {
        super();
        this.handleError = httpErrorHandler.createHandleError('SettingsService');

    }


    async presentActionSheet() {
        const actionSheet = await this.actionSheetController.create({
            header: 'Status',
            buttons: [
                {
                    text: 'Open',
                    icon: 'play',
                    handler: () => {
                        this.UpdateRestaurntStatus(2).subscribe();
                    }
                },
                {
                    text: 'Busy',
                    icon: 'pause',
                    handler: () => {
                        this.UpdateRestaurntStatus(1).subscribe();

                    }
                }, {
                    text: 'Closed',
                    role: 'destructive',
                    icon: 'close',
                    handler: () => {
                        this.UpdateRestaurntStatus(0).subscribe();
                    }
                },

            ]
        });
        await actionSheet.present();
    }


    GetDeliverySlots() {
        return this.api.get<BaseResponse<DeliveySlotsModule[]>>(APIVS.V1, Apimethods.DeliveySlots, {}, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<DeliveySlotsModule[]>) => {
                return res;
            }),
            catchError(this.handleError('GetDeliverySlots', []))
        );
    }

    GetMenuTimes() {
        return this.api.get<BaseResponse<MenuTimesModule[]>>(APIVS.V1, Apimethods.GetMenuTimes, {}, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<MenuTimesModule[]>) => {
                return res;
            }),
            catchError(this.handleError('GetMenuTimes', []))
        );
    }

    CreateMenuTimes(data: MenuTimesModule[]) {
        return this.api.post<BaseResponse<boolean>>(APIVS.V1, Apimethods.CreateMenuTimes, { data }, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<boolean>) => {
                return res;
            }),
            catchError(this.handleError('CreateMenuTimes', []))
        );
    }

    GetVendor() {
        return this.api.get<BaseResponse<Vendor>>(APIVS.V1, Apimethods.GetVendor, {}, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<Vendor>) => {
                this.shop.next(res.data);
                return res;

            }),
            catchError(this.handleError('GetVendor', []))
        );
    }

    UpdateDeliverySlot(slot) {
        return this.api.post<BaseResponse<boolean>>(APIVS.V1, Apimethods.DeliveySlotUpdate, slot, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<boolean>) => {
                return res;
            }),
            catchError(this.handleError('UpdateDeliverySlot', []))
        );
    }

    UpdateRestaurntStatus(status) {
        return this.api.post<BaseResponse<boolean>>(APIVS.V1, Apimethods.UpdateStatus, { status }, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<boolean>) => {
                this.GetVendor().subscribe();
                return res;

            }),
            catchError(this.handleError('UpdateRestaurntStatus', []))
        );
    }

    AddDeliverySlot(slot) {
        return this.api.post<BaseResponse<boolean>>(APIVS.V1, Apimethods.DeliveySlotadd, slot, this.api.base.getShopId().toString()).pipe(
            map((res: BaseResponse<boolean>) => {
                return res;
            }),
            catchError(this.handleError('AddDeliverySlot', []))
        );
    }





}