export enum storageKeys {
  Lang = 'Lang',
  UniqueId = 'UniqueId',
  authToken = 'authToken',
  userInformation = 'userInformation',
  shopId = '_Shop',
  PN = 'PNtoken'
}

export enum locals {
  en = 'en',
  ar = 'ar'
}

export enum Lang {
  English = 0,
  Arabic = 1
}
export interface ILocalString {
  valar: string,
  valen: string,
}

export interface ILocale {
  code: string;
  text: string;
  Url: string;
}

export declare type CuHttpHeaders = {
  "Content-Type": string;
  Accept?: string,
  'Access-Control-Allow-Origin'?: string,
  "Authorization": string;
  "device": string;
  "version": string;
  "userToken"?: string;
  "uniqID"?: string;
  "lang": string;
  "countryId": string;
  pushToken:string;
} & {
  [prop: string]: any;
};

export enum ServerMode {
  Local,
  Live,
  Beta
}

export class User {
  id: number;
  shopID: number;
  name: string;
}

export class Error {
  code: number;
  message: string;
  extraData: string;
}



export class APIResult
{
  errors: Error[];
  success: boolean = false;
  message: string = '';
}